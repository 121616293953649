import { gql, useLazyQuery } from '@apollo/client';
import {
	CurrencyType,
	EasGetPortfolio as Portfolio,
	EasGetPortfolioVariables,
	OrganismCurrencyListGetCurrencies as Currency,
	OrganismCurrencyListGetCurrenciesVariables,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import {
	AllocationProgress,
	AppCurrencyIcon,
	CustomCard,
	CustomDataTable,
	ITrustColumn,
} from '@app/shared/components';
import { accountTypeHumanizer, allocationPercentage } from '@app/shared/helpers';
import { strToNumber, toCurrency, useAppState, useAppTheme } from '@itrustcapital/ui';
import { Card, Spinner, Text, Button } from '@ui-kitten/components';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Toast from 'react-native-toast-message';

import { TradeCreditModal } from './TradeCreditModal';

export interface PortfolioCardProps {
	style?: StyleProp<ViewStyle>;
	accountId: number;
}

export interface AssetTableData {
	id?: number;
	asset?: string;
	allocation?: string;
	balance: number;
	price?: number;
	value?: string;
	currencyType?: CurrencyType;
}

export const EAS_GET_PORTFOLIO = gql`
	query EasGetPortfolio($where: AccountFilterInput) {
		accounts(where: $where) {
			nodes {
				id
				user {
					referralCode
					referralCodeCount
				}
				displayName
				accountNumber
				type
				inboundFundingSumValue
				outboundFundingSumValue
				inboundFundings {
					fundingAssets {
						currencyType
						amount
					}
				}
				outboundFundings {
					fundingAssets {
						currencyType
						amount
					}
				}
				wallets {
					id
					currencyType
					availableBalance
				}
				tradeFeeCredit {
					availableBalance
				}
				id
			}
		}
	}
`;

export const ORGANISM_CURRENCIES_GET_CURRENCIES = gql`
	query OrganismCurrencyListGetCurrencies($where: CurrencyFilterInput) {
		currencies(first: 50, where: $where) {
			nodes {
				id
				name
				unit
				avgPrice
			}
		}
	}
`;

export function PortfolioCard(props: PortfolioCardProps) {
	const styles = useCustomStyles();
	const portfolioValue = useAppState<string>('$0.00');
	const assetTableData = useAppState<AssetTableData[]>([]);
	const tradeFeeModalVisible = useAppState(false);
	const maestroUserAtom = useMaestroUserAtom();

	const [getPortfolio, portfolioQuery] = useLazyQuery<Portfolio, EasGetPortfolioVariables>(
		EAS_GET_PORTFOLIO,
		{
			fetchPolicy: 'no-cache',
			onCompleted,
		}
	);

	const [getCurrencies, getCurrenciesQuery] = useLazyQuery<
		Currency,
		OrganismCurrencyListGetCurrenciesVariables
	>(ORGANISM_CURRENCIES_GET_CURRENCIES, {
		variables: {
			where: {
				id: { nin: [CurrencyType.USD] },
			},
		},
		onCompleted: onCurrenciesCompleted,
		fetchPolicy: 'no-cache',
	});

	const firstAccount = portfolioQuery?.data?.accounts?.nodes?.[0];

	React.useLayoutEffect(() => {
		if (props.accountId) {
			getPortfolio({ variables: { where: { id: { eq: props.accountId! } } } });
		}
	}, [props.accountId]);

	async function onCompleted() {
		if (getCurrenciesQuery.called) {
			const data = await getCurrenciesQuery.refetch();
			onCurrenciesCompleted(data.data);

			return;
		}
		getCurrencies();
	}

	function onCurrenciesCompleted(data: Currency): void {
		const wallets = firstAccount?.wallets! || [];
		const currencies = data?.currencies?.nodes!;

		let value = 0;

		const assetData: AssetTableData[] = wallets.map((wallet) => {
			let price = 1;

			if (wallet?.currencyType !== CurrencyType.USD) {
				const priceDetails = currencies.find(
					(currency) => currency?.id === wallet?.currencyType
				);

				price = priceDetails?.avgPrice ?? 0;
			}

			const total = wallet?.availableBalance * price;

			value += total;

			return {
				id: wallet?.id,
				asset: wallet?.currencyType,
				allocation: total.toString(),
				balance: wallet?.availableBalance,
				price,
				value: total.toString(),
				currencyType: wallet?.currencyType,
			} as AssetTableData;
		});

		portfolioValue.set(toCurrency(value));
		assetTableData.set(assetData);
	}

	const walletColumns: ITrustColumn<AssetTableData>[] = [
		{
			key: 'asset',
			name: 'Asset',
			maxWidth: 150,
			center: true,
			cell: (row) => {
				return (
					<View style={styles.data.row}>
						<AppCurrencyIcon currency={row.currencyType!} style={styles.data.image} />
						<Text>{row.currencyType!}</Text>
					</View>
				);
			},
		},
		{
			key: 'allocation',
			name: 'Allocation',
			minWidth: 180,
			maxWidth: 200,
			cell: (row) => {
				const value =
					(row.balance * (row.price ? row.price : 0)) / strToNumber(portfolioValue.get);

				return (
					<View style={styles.data.progressContainer}>
						<Text>{allocationPercentage(value)}</Text>
						<AllocationProgress percentage={value * 100} style={styles.data.progress} />
					</View>
				);
			},
		},
		{
			key: 'balance',
			name: 'Available Balance',
			minWidth: 200,
			right: true,
			selector: (row) =>
				row.currencyType === CurrencyType.USD ? toCurrency(row.balance) : row.balance,
		},
		{
			key: 'price',
			name: 'Price',
			minWidth: 200,
			right: true,
			selector: (row) => toCurrency(row?.price!),
		},
		{
			key: 'value',
			name: 'Value',
			minWidth: 200,
			right: true,

			selector: (row) => toCurrency(row.balance * row?.price!),
		},
	];

	const tradeFeeCreditText =
		firstAccount?.tradeFeeCredit?.availableBalance > 0
			? `$${(firstAccount!.tradeFeeCredit!.availableBalance / 0.01).toFixed(2)}`
			: '$0.00';

	return (
		<CustomCard header="Portfolio">
			<View style={[styles.main.container, props.style]}>
				<View style={styles.top.row}>
					<Card style={styles.top.card}>
						{portfolioQuery?.loading ? (
							<View style={styles.main.spinner}>
								<Spinner size="medium" status="basic" />
							</View>
						) : (
							<>
								<View style={styles.top.row}>
									<Text category="c2" style={[styles.top.text, styles.top.label]}>
										Account Type:
									</Text>
									<Text category="s1" style={styles.top.text}>
										{accountTypeHumanizer(firstAccount?.type!)}
									</Text>
								</View>
								<View style={styles.top.row}>
									<Text category="c2" style={[styles.top.text, styles.top.label]}>
										Account Number:
									</Text>
									<Text category="s1" style={styles.top.text}>
										{firstAccount?.accountNumber!}
									</Text>
								</View>
							</>
						)}
					</Card>

					<Card style={styles.top.card}>
						{portfolioQuery?.loading || getCurrenciesQuery?.loading ? (
							<View style={styles.main.spinner}>
								<Spinner size="medium" status="basic" />
							</View>
						) : (
							<View>
								<Text category="s1" style={styles.top.text}>
									Portfolio Value:
								</Text>
								<Text category="h3">{portfolioValue.get}</Text>
							</View>
						)}
					</Card>

					<Card style={styles.top.card}>
						{portfolioQuery?.loading ? (
							<View style={styles.main.spinner}>
								<Spinner size="medium" status="basic" />
							</View>
						) : (
							<View>
								<Text category="s1" style={styles.top.text}>
									Trade Credit (Volume):
								</Text>

								<View
									style={{
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Text category="h3">{tradeFeeCreditText}</Text>

									{maestroUserAtom?.isAdmin && (
										<Button
											size={'tiny'}
											onPress={() => {
												tradeFeeModalVisible.set(true);
											}}
										>
											Add
										</Button>
									)}
								</View>
							</View>
						)}
					</Card>

					<Card style={styles.top.card}>
						{portfolioQuery?.loading ? (
							<View style={styles.main.spinner}>
								<Spinner size="medium" status="basic" />
							</View>
						) : (
							<View>
								<Text category="s1" style={styles.top.text}>
									Lifetime Referrals:
								</Text>
								<Text category="h3">{firstAccount?.user?.referralCodeCount}</Text>
							</View>
						)}
					</Card>
				</View>

				<View style={styles.main.assetTable}>
					<CustomDataTable columns={walletColumns} data={assetTableData.get} />
				</View>
			</View>

			<TradeCreditModal
				accountId={props?.accountId}
				visible={tradeFeeModalVisible.get}
				toggleVisible={() => {
					tradeFeeModalVisible.set(!tradeFeeModalVisible.get);
				}}
				onSuccess={(amount) => {
					tradeFeeModalVisible.set(false);
					Toast.show({
						type: 'success',
						text2: `Account successfully credited $${amount}.`,
					});
				}}
				onError={() => {
					tradeFeeModalVisible.set(false);
					Toast.show({
						type: 'error',
						text2: 'Failed to credit account.',
					});
				}}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			container: {
				paddingVertical: 8,
				paddingHorizontal: 12,
			},
			header: {
				marginBottom: 24,
			},
			usdTable: {
				marginTop: 16,
			},
			assetTable: {
				marginTop: 32,
			},
			spinner: {
				width: '100%',
				alignItems: 'center',
			},
		}),
		top: StyleSheet.create({
			row: {
				flexDirection: 'row',
				alignContent: 'center',
				gap: '8px',
				flexWrap: 'wrap',
			},
			card: {
				flex: 1,
				justifyContent: 'center',
				alignContent: 'center',
				maxWidth: 240,
				minWidth: 240,
				height: 100,
				shadowColor: theme['shadow-color-100'],
				shadowOpacity: 0.2,
				shadowOffset: { width: 0, height: 4 },
				shadowRadius: 4,
			},
			margin: {
				marginRight: 32,
			},
			label: {
				color: theme['color-basic-600'],
			},
			text: {
				flex: 1,
				textAlign: 'left',
				marginVertical: 10,
			},
			text2: {
				flex: 1,
				textAlign: 'left',
				marginVertical: 7,
			},
		}),
		data: StyleSheet.create({
			icon: {
				padding: 15,
			},
			image: {
				width: 42,
				height: 42,
				margin: 15,
			},
			currency: {
				marginLeft: 6,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			progressContainer: {
				flexDirection: 'column',
				width: '100%',
			},
			progress: {
				marginTop: 8,
			},
		}),
	};
}
